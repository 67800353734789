


































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import { getLongRangeGoodsMscGoodsLists } from "@/api/goods";
import { RequestPaging } from "@/utils/util";
import { apiInfo } from "@/api/settlement";
import lsPagination from "@/components/ls-pagination.vue";
import { getGoodsGoodsCategoryLists } from '@/api/decorate'

@Component({
    components: { LsDialog, lsPagination },
})
export default class DetailsPage extends Vue {
    apiInfo = apiInfo;
    pager: RequestPaging = new RequestPaging();
    count: any = {
        inStore: 0,
        newThisMonth: 0,
        onSale: 0,
        soldOut: 0,
    };
  categoryList = [];
  settlementInfo = {
    year:'',
    month:'',
    amount:0,
    settlement_sn:'',
  };
    searchObj: any = {
        order_sn: "",
        local_sn: "",
        deduction_type: "",
        order_type: "",
    };
  getGoodsCategoryLists() {
    getGoodsGoodsCategoryLists({}).then((res: any) => {
      this.categoryList = res
    })
  }
    //选品库数据
    getGoodsLists() {
        this.pager
            .request({
                callback: apiInfo,
                params: this.searchObj,
            })
            .then((res) => {
                this.count = res.count;
            });
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getGoodsLists();
    }
    back() {
        this.$router.push({
            path: "/finance/platform_settlement_lists",
            query: {
                t: 1,
                page: this.$route.query.page || 1,
                size: this.$route.query.size || 10,
            },
        });
    }
    async created() {
        this.searchObj.settlement_id = this.$route.query.id || null;
        this.settlementInfo.year = this.$route.query.year || null;
        this.settlementInfo.month = this.$route.query.month || null;
        this.settlementInfo.amount = this.$route.query.amount || null;
        this.settlementInfo.settlement_sn = this.$route.query.settlement_sn || null;

        this.getGoodsCategoryLists()
        this.getGoodsLists();
    }
}
