var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details-page"},[_c('div',{staticClass:"ls-card"},[_c('el-page-header',{attrs:{"content":"结算单详情"},on:{"back":_vm.back}})],1),_c('div',{staticClass:"ls-card"},[_c('div',{staticClass:"flex font-size-20"},[(_vm.settlementInfo.year !='' && _vm.settlementInfo.month !='')?_c('span',[_vm._v(_vm._s(_vm.settlementInfo.year)+"-"+_vm._s(_vm.settlementInfo.month))]):_vm._e(),(_vm.settlementInfo.settlement_sn !='')?_c('span',{staticClass:"m-r-20 m-l-20"},[_vm._v("结算单:"+_vm._s(_vm.settlementInfo.settlement_sn)+" ")]):_vm._e(),(_vm.settlementInfo.amount > 0)?_c('span',[_vm._v("汇总金额："),_c('label',{staticClass:"primary"},[_vm._v(_vm._s(_vm.settlementInfo.amount)+"元")])]):_vm._e()]),_c('div',{staticClass:"header m-t-20 p-t-20"},[_c('el-form',{staticClass:"demo-form-inline",attrs:{"label-width":"100px","inline":true,"model":_vm.searchObj,"size":"small"}},[_c('el-form-item',{attrs:{"label":"商品名称"}},[_c('el-input',{staticClass:"ls-input",attrs:{"placeholder":"请输入商品名称"},model:{value:(_vm.searchObj.goods_name),callback:function ($$v) {_vm.$set(_vm.searchObj, "goods_name", $$v)},expression:"searchObj.goods_name"}})],1),_c('el-form-item',{attrs:{"label":"SKU"}},[_c('el-input',{staticClass:"ls-input",attrs:{"placeholder":"请输入SKU"},model:{value:(_vm.searchObj.sku),callback:function ($$v) {_vm.$set(_vm.searchObj, "sku", $$v)},expression:"searchObj.sku"}})],1),_c('el-form-item',{attrs:{"label":"订单号"}},[_c('el-input',{staticClass:"ls-input",attrs:{"placeholder":"请输入订单号"},model:{value:(_vm.searchObj.local_sn),callback:function ($$v) {_vm.$set(_vm.searchObj, "local_sn", $$v)},expression:"searchObj.local_sn"}})],1),_c('el-form-item',{attrs:{"label":"商品分类"}},[_c('el-cascader',{attrs:{"options":_vm.categoryList,"props":{
                        value: 'name',
                        label: 'name',
                        children: 'sons',
                        emitPath: false,
                    },"show-all-levels":false,"collapse-tags":"","filterable":"","clearable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var node = ref.node;
                    var data = ref.data;
return (data.level <= 2)?[_c('span',[_vm._v(_vm._s(data.name))]),(!node.isLeaf)?_c('span',[_vm._v(" ("+_vm._s(data.sons.length)+") ")]):_vm._e()]:undefined}}],null,true),model:{value:(_vm.searchObj.goods_cate),callback:function ($$v) {_vm.$set(_vm.searchObj, "goods_cate", $$v)},expression:"searchObj.goods_cate"}})],1),_c('el-form-item',{attrs:{"label":"抵扣类型"}},[_c('el-select',{attrs:{"clearable":""},model:{value:(_vm.searchObj.deduction_type),callback:function ($$v) {_vm.$set(_vm.searchObj, "deduction_type", $$v)},expression:"searchObj.deduction_type"}},_vm._l((_vm.pager.extra
                                .deduction_type),function(item,index){return _c('el-option',{attrs:{"label":item,"value":index}})}),1)],1),_c('el-form-item',{attrs:{"label":"订单类型"}},[_c('el-select',{attrs:{"clearable":""},model:{value:(_vm.searchObj.order_type),callback:function ($$v) {_vm.$set(_vm.searchObj, "order_type", $$v)},expression:"searchObj.order_type"}},_vm._l((_vm.pager.extra.order_type),function(item,index){return _c('el-option',{attrs:{"label":item,"value":index}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.getGoodsLists}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"style"},on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)]),_c('div',{staticClass:"ls-card"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pager.loading),expression:"pager.loading"}],ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.pager.lists,"border":"","size":"mini"}},[_c('el-table-column',{attrs:{"label":"预付款流水号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{staticClass:"_cell"},[_vm._v(" "+_vm._s(row.order_sn)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"订单编号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{staticClass:"_cell"},[_vm._v(" "+_vm._s(row.local_sn)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"SKUID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{},[_c('div',{staticClass:"cell-list"},_vm._l((row.goods),function(item,index){return _c('p',{key:index,staticClass:"cell-item"},[_vm._v(" "+_vm._s(item.sku)+" ")])}),0)])]}}])}),_c('el-table-column',{attrs:{"label":"商品名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{},[_c('div',{staticClass:"cell-list"},_vm._l((row.goods),function(item,index){return _c('p',{key:index,staticClass:"cell-item"},[_vm._v(" "+_vm._s(item.goods_name)+" ")])}),0)])]}}])}),_c('el-table-column',{attrs:{"label":"数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{},[_c('div',{staticClass:"cell-list"},_vm._l((row.goods),function(item,index){return _c('p',{key:index,staticClass:"cell-item"},[_vm._v(" "+_vm._s(item.goods_num)+" ")])}),0)])]}}])}),_c('el-table-column',{attrs:{"label":"所属分类"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{},[_c('div',{staticClass:"cell-list"},_vm._l((row.goods),function(item,index){return _c('p',{key:index,staticClass:"cell-item"},[_vm._v(" "+_vm._s(item.goods_cate)+" ")])}),0)])]}}])}),_c('el-table-column',{attrs:{"label":"商品归属"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{},[_c('div',{staticClass:"cell-list"},_vm._l((row.goods),function(item,index){return _c('p',{key:index,staticClass:"cell-item"},[_vm._v(_vm._s(item.tag))])}),0)])]}}])}),_c('el-table-column',{attrs:{"label":"采购成本"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{},[_c('div',{staticClass:"cell-list"},_vm._l((row.goods),function(item,index){return _c('p',{key:index,staticClass:"cell-item"},[_vm._v(_vm._s(item.price))])}),0)])]}}])}),_c('el-table-column',{attrs:{"label":"售后单编号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{staticClass:"_cell"},[_vm._v(_vm._s(row.after_sale_sn))])]}}])}),_c('el-table-column',{attrs:{"label":"结算总额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{staticClass:"_cell"},[_vm._v(_vm._s(row.order_amount))])]}}])}),_c('el-table-column',{attrs:{"label":"结算运费总额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{staticClass:"_cell"},[_vm._v(_vm._s(row.freight))])]}}])}),_c('el-table-column',{attrs:{"label":"抵扣类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{},[_c('div',{staticClass:"cell-list"},_vm._l((row.goods),function(item,index){return _c('p',{key:index,staticClass:"cell-item"},[_vm._v(" "+_vm._s(item.deduction_type_str)+" ")])}),0)])]}}])}),_c('el-table-column',{attrs:{"label":"订单完成时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('div',{staticClass:"_cell"},[_vm._v(_vm._s(row.handle_time))])]}}])})],1)],1),_c('div',{staticClass:"flex pagination"},[_c('ls-pagination',{on:{"change":function($event){return _vm.getGoodsLists()}},model:{value:(_vm.pager),callback:function ($$v) {_vm.pager=$$v},expression:"pager"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }