import request from "@/plugins/axios";

//获取分类列表
export const getMarketGoodsGoodsCategoryLists = (params: any) =>
  request.get("market_goods.GoodsCategory/lists", { params });

//获取平台商品分类列表
export const getGoodsGoodsCategoryLists = (params: any) =>
  request.get("market_goods.GoodsCategory/catelists", { params });

//获取分类列表联合查询
export const getMarketGoodsGoodsCategoryCommonLists = (params: any) =>
  request.get("market_goods.GoodsCategory/commonLists", { params });

//新增分类
export const apiMarketGoodsGoodsCategoryAdd = (data: any) =>
  request.post("market_goods.GoodsCategory/add", data);

//删除分类
export const apiMarketGoodsGoodsCategoryDel = (data: any) =>
  request.post("market_goods.GoodsCategory/del", data);

//获取分类信息
export const getMarketGoodsGoodsCategoryDetail = (params: any) =>
  request.get("market_goods.GoodsCategory/detail", { params });

//切换是否分类显示
export const apiMarketGoodsGoodsCategoryStatus = (data: any) =>
  request.post("market_goods.GoodsCategory/status", data);

//编辑分类
export const apiMarketGoodsGoodsCategoryEdit = (data: any) =>
  request.post("market_goods.GoodsCategory/edit", data);

//挑选商品列表
export const getMarketGoodsGoodsLists = (params: any) =>
  request.get("market_goods.Goods/lists", { params });

//已挑选商品列表
export const getMarketGoodsGoodsMarketGoodsLists = (params: any) =>
  request.get("market_goods.Goods/marketGoodsLists", { params });

//添加绑定商品
export const apiMarketGoodsGoodsAddCategoryGoods = (data: any) =>
  request.post("market_goods.Goods/addCategoryGoods", data);

//删除绑定商品
export const apiMarketGoodsGoodsDeleteCategoryGoods = (data: any) =>
  request.post("market_goods.Goods/deleteCategoryGoods", data);

//删除绑定所有分类商品
export const apiMarketGoodsGoodsDeleteGoods = (data: any) =>
  request.post("market_goods.Goods/deleteGoods", data);

//集采商品上下架
export const apiMarketGoodsGoodssStatusGoods = (data: any) =>
  request.post("market_goods.Goods/statusGoods", data);
